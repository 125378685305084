import React from 'react';
import moment from 'moment';

import { useProduct } from 'context/product.context';
import { ProductPlatformLink } from './ProductPlatformLink';
import { ProductPlatform } from './ProductPlatform';
import PropTypes from 'prop-types';

const getDateInFormat = (date) => {
  let month = moment(date).format('MMM').split('');
  if (month.length > 3) {
    month = month.slice(0, -1);
  }
  const splitted = date.split('-');
  if (splitted.length === 1) {
    return date;
  }
  const day = splitted[2];
  month[0] = month[0].toUpperCase();
  return `${day} ${month.join('')}`;
};

export const ProductHeaderInfo = ({ isInner }) => {
  const { productData } = useProduct();
  const update = isInner ? productData?.last_update : productData?.updated_at;
  return (
    <div className="flex items-center mb-2">
      <div className="flex items-center divide-x divider-gray-230 space-x-2 lg:space-x-4">
        <ProductPlatform />

        <div className="text-xs text-gray-400 pl-2 lg:pl-4 leading-4">
          Обновлено {update && getDateInFormat(update)}
        </div>
        {productData?.days_on_site !== undefined && productData?.days_on_site !== null && (
          <div className="text-xs text-gray-400 pl-2 lg:pl-4 leading-4">
            Дней на сайте: {productData?.days_on_site}
          </div>
        )}
        {!isInner && (
          <div className="text-xs text-gray-400 pl-2 lg:pl-4 leading-4">
            Продано более {productData?.were_bought?.toLocaleString('ru')} шт
          </div>
        )}
      </div>
      <ProductPlatformLink className="hidden lg:flex" isInner={isInner} />
    </div>
  );
};

ProductHeaderInfo.propTypes = {
  isInner: PropTypes.bool,
};
