import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import cn from 'classnames';

import LoginNavigation from 'components/LoginNavigation';
import { deleteStateKey } from 'helpers/utils';
import ButtonPrimary from 'components/ButtonPrimary';
import LoadingIcon from 'assets/icons/LoadingIcon';

const TextInput = ({
  name,
  value,
  label,
  type,
  onChange,
  error,
  expanded,
  checkStrength,
  autoFocus,
}) => {
  const handlePhoneChange = (e) => {
    let content = e.target.value;
    if (!content) return;
    content = Array.from(content).filter((ltr) => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58);
    switch (content[0]) {
      case '8':
        content[0] = '7';
        break;
      case '9':
        content.unshift('7');
        break;
      default:
        break;
    }

    let [countryCode, operatorCode, number3, number21, number22] = [
      content[0],
      content.slice(1, 4).join(''),
      content.slice(4, 7).join(''),
      content.slice(7, 9).join(''),
      content.slice(9, 11).join(''),
    ];

    e.target.value = countryCode?.length ? `+${countryCode}` : '';
    if (operatorCode.length) e.target.value += `(${operatorCode}`;
    if (number3.length) e.target.value += `)${number3}`;
    if (number21.length) e.target.value += `-${number21}`;
    if (number22.length) e.target.value += `-${number22}`;
    onChange(e);
  };
  return (
    <div className="mb-7">
      <div className={`relative ${expanded && 'md:flex md:-mx-36 md:px-36'}`}>
        <label
          className={`block mb-2 text-black flex-auto pr-3 ${
            expanded &&
            'md:absolute md:left-0 md:w-36 md:h-full md:flex md:items-center md:justify-end md:mb-0'
          }`}
          htmlFor={name}
        >
          <span className="text-red-600 pr-1">*</span>
          {label}
        </label>
        <input
          className="w-full appearance-none border border-gray-200 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green"
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={type === 'tel' ? handlePhoneChange : onChange}
          autoFocus={autoFocus}
        />
      </div>
      {checkStrength && (
        <>
          {/*<div className="w-full h-0.5 bg-gray-300 my-4">*/}
          {/*  <div*/}
          {/*    className="h-0.5"*/}
          {/*    style={{*/}
          {/*      width: `${getPasswordStrength(value)}%`,*/}
          {/*      backgroundColor: getPasswordProgressColor(getPasswordStrength(value)),*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</div>*/}
          <ul className="text-gray-500 mt-2 list-disc pl-4">
            <li>не менее 6 символов</li>
          </ul>
        </>
      )}

      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </div>
  );
};
TextInput.defaultProps = {
  expanded: true,
  checkStrength: false,
};
TextInput.propTypes = {
  type: PropTypes.oneOf(['text', 'password']).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  expanded: PropTypes.bool,
  checkStrength: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

const Checkbox = ({ name, label, onChange }) => (
  <div className="mb-6">
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="h-4 w-4 text-green border-green border rounded form-checkbox focus:ring-2 focus:ring-offset-2 focus:ring-green"
          onChange={onChange}
        />
      </div>
      <div className="ml-3">
        <label htmlFor={name} className="text-gray-700">
          {label}
        </label>
      </div>
    </div>
  </div>
);
Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  desc: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

const AuthorizationForm = ({
  showNavigation,
  title,
  fields,
  initialValues,
  onSubmit,
  submitLabel,
  superError,
  messageText,
  successMessage,
  showButtonLoginOnMessage,
}) => {
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState(
    Object.keys(initialValues).map((key) => ({ [key]: false })),
  );

  const [error, setError] = useState({});
  const [message, setMessage] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);

  const history = useHistory();

  const setFieldValue = (name) => {
    return ({ target: { value } }) => {
      setValues((oldValues) => ({ ...oldValues, [name]: value }));
      setTouched((prev) => ({ ...prev, [name]: true }));
    };
  };

  const setCheckboxValue = (name) => {
    return ({ target: { checked } }) => {
      setValues((oldValues) => ({ ...oldValues, [name]: checked }));
      setTouched((prev) => ({ ...prev, [name]: true }));
    };
  };

  const isPasswordStrong = (password) => password.length >= 6;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.phone?.length < 16) {
      setIsPhoneError(true);
      return;
    } else {
      setIsPhoneError(false);
    }
    setLoading(true);
    onSubmit(values)
      .then((data) => {
        setLoading(false);
        if (!data) return;

        data = data.data || data;

        if (data.error) {
          setError(data.error?.[0] || '');
        } else {
          setError(null);
        }

        if (data.success || data.status === true) {
          setMessage(successMessage);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    const checkStrength = Object.values(fields).find((field) => field.checkStrength);

    if (
      'rePassword' in values &&
      'password' in values &&
      values.password !== values.rePassword &&
      touched.rePassword
    ) {
      setError((prev) => ({ ...prev, rePassword: 'Пароли не совпадают' }));
    } else {
      setError((prev) => deleteStateKey(prev, 'rePassword'));
    }

    if (
      Object.keys(initialValues).some((key) => !values[key]) ||
      (checkStrength && !isPasswordStrong(values[checkStrength.name])) ||
      (values.password !== values.rePassword && touched.rePassword)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [fields, initialValues, values, touched.rePassword]);

  return (
    <div
      className={`flex items-center flex-col max-w-full w-full mb-8 ${message ? '' : 'sm:w-72'}`}
    >
      {message ? (
        <>
          <div className="text-base text-center mb-4">{message}</div>
          {showButtonLoginOnMessage && (
            <ButtonPrimary onClick={() => history.push('/')} label="Войти" />
          )}
        </>
      ) : (
        <>
          {title && (
            <h1 className="mb-6 pb-3 text-xl border-gray-200 border-b max-w-full w-72 text-left text-center sm:text-left">
              {title}
            </h1>
          )}
          {showNavigation && <LoginNavigation />}

          {superError && (
            <div className="bg-orange w-full text-white text-center p-1.5 text-sm mb-8">
              {superError}
            </div>
          )}

          {messageText && messageText}

          <form onSubmit={handleSubmit} className="w-full">
            {fields.map((field, i) => (
              <React.Fragment key={i}>
                {field.type === 'checkbox' ? (
                  <Checkbox
                    label={field.label}
                    onChange={setCheckboxValue(field.name)}
                    name={field.name}
                    desc={field.desc}
                  />
                ) : (
                  <TextInput
                    error={
                      field.type === 'tel' && isPhoneError
                        ? 'Введите корректный номер телефона'
                        : error?.[field.name] || null
                    }
                    label={field.label}
                    type={field.type}
                    onChange={setFieldValue(field.name)}
                    name={field.name}
                    value={values[field.name]}
                    expanded={field.expanded}
                    desc={field.desc}
                    checkStrength={field.checkStrength}
                    autoFocus={i === 0}
                  />
                )}
              </React.Fragment>
            ))}

            <button
              disabled={disabled}
              type="submit"
              className="relative min-h-[38px] w-full inline-flex justify-center py-2 px-4 border border-green transition-all rounded-md text-white bg-green hover:bg-transparent hover:text-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
            >
              {loading ? (
                <LoadingIcon
                  className={cn('w-5 h-5 animate-spin', {
                    ['text-green']: loading,
                    ['text-white']: !loading,
                  })}
                />
              ) : (
                submitLabel
              )}
            </button>
          </form>
        </>
      )}
    </div>
  );
};

AuthorizationForm.defaultProps = {
  showNavigation: true,
  superError: null,
  title: null,
  messageText: null,
  successMessage: null,
  showButtonLoginOnMessage: false,
};

AuthorizationForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      type: PropTypes.oneOf(['text', 'password', 'checkbox']).isRequired,
      desc: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      checkPassword: PropTypes.bool,
    }),
  ).isRequired,
  submitLabel: PropTypes.string.isRequired,
  showNavigation: PropTypes.bool,
  superError: PropTypes.string,
  title: PropTypes.string,
  messageText: PropTypes.string,
  successMessage: PropTypes.string,
  showButtonLoginOnMessage: PropTypes.bool,
};

export default AuthorizationForm;
