import React from 'react';
import {
  BY_PHRASES,
  BY_PRODUCTS,
  PAGE_INNER_PRODUCTS_SETTINGS,
  PAGE_SEO_HINTS,
  PAGE_SEO_SMART,
} from '../constants';
import {
  PAGE_ADVERTISING_RATES,
  PAGE_API_KEYS,
  PAGE_INNER_DELIVERY_TAB,
  // PAGE_INNER_PRICES_TAB,
  PAGE_INNER_PRODUCTS,
  PAGE_POSITIONS,
  PAGE_SEO_BY_PHRASE,
  PAGE_SEO_COMPARE,
  PAGE_SEO_MONITORING,
} from 'constants';
import { useMainLink } from '../hooks/useLocationPath';
import {
  isApiKeysPage,
  isFaqPage,
  isInnerDeliveryTabPage,
  isInnerPricesTabPage,
  isInnerProductSettings,
  isInnerTablePage,
  isMainPage,
} from '../utils/pages';
import AnalyticIcon from '../assets/icons/AnalyticIcon';
import SwitcherIcon from '../assets/icons/SwitcherIcon';
import SettingsIcon from '../assets/icons/SettingsIcon';
import AimIcon from '../assets/icons/AimIcon';

const NavigationContext = React.createContext();
NavigationContext.displayName = 'Navigation.Context';

const NAVIGATION_BLOCKS = [
  {
    title: 'Аналитика конкурентов',
    icon: <AnalyticIcon />,
    items: [
      {
        name: 'Аналитика по категориям',
        link: '/main',
      },
      {
        name: 'Аналитика по продавцам',
        link: '/main/sellers',
      },
      {
        name: 'Аналитика по брендам',
        link: '/main/brands',
      },
      {
        name: 'Аналитика по товарам',
        link: '/main/products',
      },
      {
        name: 'Аналитика по складам',
        link: '/main/warehouses',
      },
      {
        name: 'Тренды',
        link: '/main/dynamic',
      },
      {
        name: 'Ценовая сегментация',
        link: '/main/price-segments',
      },
      {
        name: 'По дням',
        link: '/main/sales',
      },
    ],
  },
  {
    title: 'Управление продажами',
    icon: <SwitcherIcon />,
    items: [
      {
        name: 'Динамика по дням',
        link: '/inner/in-days-2',
      },
      {
        name: 'Аналитика по товарам',
        link: '/inner/products',
      },
      {
        name: 'Заказы. Продажи. Возвраты',
        link: '/inner/sales',
      },
      {
        name: 'Аналитика по складам',
        link: '/inner/warehouses',
      },
      {
        name: 'Еженедельные отчеты',
        link: '/inner/weeks',
      },
      {
        name: 'Расчет поставки',
        link: '/inner/delivery',
      },
      {
        name: 'Настройка себестоимости',
        link: '/inner/products-settings',
      },
      // {
      //   name: 'Управление ценами',
      //   link: '/inner/prices',
      // },
      {
        name: 'API Ключи',
        link: '/api-keys',
      },
    ],
  },
  {
    title: 'SEO продвижение',
    icon: <SettingsIcon />,
    items: [
      {
        name: 'Подбор слов по товарам',
        link: '/seo/products',
      },
      {
        name: 'Подбор слов по фразам',
        link: '/seo/phrases',
      },
      {
        name: 'Подбор слов по подсказкам',
        link: '/seo/hints',
      },
      {
        name: 'Сравнение товаров',
        link: '/seo/compare',
      },
      {
        name: 'Мониторинг запросов',
        link: '/seo/monitoring',
      },
      {
        name: 'Проверка позиций по фразе',
        link: '/seo/by-phrase',
      },
      {
        name: 'Умный подбор',
        link: '/seo/smart',
      },
      // {
      //   name: 'Умное описание',
      // },
    ],
  },
  {
    title: 'Рекламные ставки',
    icon: <AimIcon />,
    items: [
      {
        name: 'Проверка рекламных ставок',
        link: '/promotion-position',
      },
    ],
  },
];

const NavigationProvider = (props) => {
  const SEO_LINKS = [
    {
      label: 'Подбор Слов',
      to: `/seo/${BY_PRODUCTS}`,
      id: 'page-seo-words',
      isActive:
        window.location.pathname === `/seo/${BY_PRODUCTS}` ||
        window.location.pathname === `/seo/${BY_PHRASES}` ||
        window.location.pathname.includes(PAGE_SEO_HINTS),
    },
    {
      label: 'Сравнение',
      to: PAGE_SEO_COMPARE,
      id: 'page-seo-compare',
      isActive: window.location.pathname === PAGE_SEO_COMPARE,
    },
    {
      label: 'Мониторинг',
      to: PAGE_SEO_MONITORING,
      id: 'page-seo-monitoring',
      isActive: window.location.pathname === PAGE_SEO_MONITORING,
    },

    {
      label: 'Позиции Товаров',
      to: PAGE_SEO_BY_PHRASE,
      id: 'page-seo-by-phrase',
      isActive: window.location.pathname === PAGE_SEO_BY_PHRASE,
    },
    {
      label: 'Умный Подбор',
      to: PAGE_SEO_SMART,
      id: 'page-seo-smart',
      isActive: window.location.pathname === PAGE_SEO_SMART,
    },
    // {
    //   label: 'Подсказки',
    //   to: PAGE_SEO_HINTS,
    //   id: 'page-seo-hints',
    //   isActive: window.location.pathname === PAGE_SEO_HINTS,
    // },
  ];

  const OUTER_LINKS = [
    {
      label: 'Аналитика',
      to: useMainLink(),
      id: 'link-main',
      isActive: isMainPage(),
    },
    {
      label: 'Faq',
      to: '/faq',
      id: 'link-faq',
      isActive: isFaqPage(),
    },
  ];

  const INNER_LINKS = [
    {
      label: 'Аналитика',
      to: PAGE_INNER_PRODUCTS,
      id: 'page-inner-sales',
      isActive:
        isInnerTablePage() &&
        !isInnerPricesTabPage() &&
        !isInnerDeliveryTabPage() &&
        !isInnerProductSettings(),
    },
    {
      label: 'Расчет поставки',
      to: PAGE_INNER_DELIVERY_TAB,
      id: 'page-inner-delivery',
      isActive: isInnerDeliveryTabPage(),
    },
    // {
    //   label: 'Цены',
    //   to: PAGE_INNER_PRICES_TAB,
    //   id: 'page-inner-prices',
    //   isActive: isInnerPricesTabPage(),
    // },
    {
      label: 'Себестоимость',
      to: PAGE_INNER_PRODUCTS_SETTINGS,
      id: 'page-inner-products-settings',
      isActive: isInnerProductSettings(),
    },
    {
      label: 'API-ключи',
      to: PAGE_API_KEYS,
      id: 'link-api-keys',
      isActive: isApiKeysPage(),
    },
  ];

  const EXT_LINKS = [
    {
      label: 'Рекламные ставки',
      to: PAGE_ADVERTISING_RATES,
      id: 'link-advertising',
    },
    {
      id: 'link-seo',
      label: 'SEO продвижение',
      to: `/seo/${BY_PRODUCTS}`,
    },
    {
      id: 'link-positions',
      label: 'Позиции и доли',
      to: PAGE_POSITIONS,
    },
  ];

  const value = {
    OUTER_LINKS,
    INNER_LINKS,
    SEO_LINKS,
    EXT_LINKS,
    NAVIGATION_BLOCKS,
  };

  return <NavigationContext.Provider value={value} {...props} />;
};

const useNavigation = () => React.useContext(NavigationContext);

export { NavigationProvider, useNavigation };
