import React, { useRef, useState } from 'react';
import cn from 'classnames';

import NavList from 'components/NavList';
import InputList from 'components/InputList';
import { changeStateScopeValueByIndex, changeStateValueByIndex } from 'helpers/utils';
import ButtonPrimary from 'components/ButtonPrimary';
import { useSEOSearch } from 'context/seo.search.context';
import { BY_PHRASES, BY_PRODUCTS } from 'constants/seo';
import Alert from 'components/Alert';
import Popover from '../../../components/Popover';
import { useAuth } from '../../../context/auth.context';

const MIN_AMOUNT = 5;

const initialValues = (length) => {
  const values = [];

  for (let i = 0; i < length; i++) {
    values.push('');
  }

  return values;
};

const PRODUCTS_LIMIT = 100;
const PHRASES_LIMIT = 4;
const PHRASES_MAX_LIMIT = 100;

const Form = () => {
  const [products, setProducts] = useState(initialValues(MIN_AMOUNT));
  const [phrases, setPhrases] = useState(initialValues(PHRASES_LIMIT));
  const [error, setError] = useState(null);
  const { by, setSearchParams } = useSEOSearch();
  const { noTariff } = useAuth();
  const tooltipNoTariffRef = useRef();

  const modifyPaste = (e, index, limit, word) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text');
    const values = text.split('\n');

    if (index + values.length > limit) {
      setError(`Можно добавлять не более ${limit} ${word}`);
    }

    return values;
  };

  const handleChangeProducts = (i) => (e) => {
    setProducts((prev) => changeStateValueByIndex(prev, i, e.target.value));
  };

  const handlePasteProducts = (i) => (e) => {
    const values = modifyPaste(e, i, PRODUCTS_LIMIT, 'товаров');

    setProducts((prev) => changeStateScopeValueByIndex(prev, i, values, PRODUCTS_LIMIT));
  };

  const handleChangePhrases = (i) => (e) => {
    setPhrases((prev) => changeStateValueByIndex(prev, i, e.target.value));
  };

  const handlePastePhrases = (i) => (e) => {
    const values = modifyPaste(e, i, PHRASES_MAX_LIMIT, 'фраз');

    setPhrases((prev) => changeStateScopeValueByIndex(prev, i, values, PHRASES_MAX_LIMIT));
  };

  const getValues = () => {
    let values = by === BY_PRODUCTS ? products : by === BY_PHRASES ? phrases : null;

    return values.filter((value) => value);
  };

  let values = getValues();

  const handleSubmit = async () => {
    setSearchParams(values);
  };

  const handleClear = async () => {
    if (by === BY_PRODUCTS) {
      setProducts(initialValues(MIN_AMOUNT));
    }

    if (by === BY_PHRASES) {
      setPhrases(initialValues(PHRASES_LIMIT));
    }
  };

  const getError = () => {
    if (!error) return [];

    return [
      {
        key: 'products-limit',
        message: error,
        type: 'error',
      },
    ];
  };

  const removeError = () => setError(null);

  return (
    <div className="pt-4">
      <div className="max-w-[800px] px-8">
        <h1>Подбор слов для увеличения продаж</h1>

        <div className="text-gray-400 mt-1 mb-4">
          {by === BY_PRODUCTS && (
            <>
              WeСheck подберёт слова, которые важно использовать при наполнении карточек товара.
              Ваши товары улучшат позиции в&nbsp;поиске и станут продаваться лучше.
            </>
          )}

          {by === BY_PHRASES && (
            <>
              Поиск слов и фраз, которые стоит добавить на карточку своего товара, чтобы покрыть
              больше запросов.
            </>
          )}
        </div>

        <NavList
          items={
            // window.location.hostname === 'go.wecheck.ru'
            // ? [
            //     {
            //       link: `/seo/${BY_PRODUCTS}`,
            //       title: 'По товарам',
            //     },
            //     {
            //       link: `/seo/hints`,
            //       title: 'По подсказкам',
            //     },
            //   ]
            // :
            [
              {
                link: `/seo/${BY_PRODUCTS}`,
                title: 'По товарам',
              },
              {
                link: `/seo/${BY_PHRASES}`,
                title: 'По поисковым фразам',
              },
              {
                link: `/seo/hints`,
                title: 'По подсказкам',
              },
            ]
          }
        />

        {error && <Alert hide={removeError} items={getError()} placement="top" />}

        <div className="rounded-lg border border-gray-250 p-4 bg-gray-50 my-4 text-gray-400">
          {by === BY_PRODUCTS && (
            <>
              WeCheck покажет все поисковые запросы, по которым встречались добавленные товары.
              Проверяем за последние 30 дней.
            </>
          )}

          {by === BY_PHRASES && (
            <>
              WeCheck найдет топ 30 товаров по указанным поисковым фразам. Далее мы покажем все
              поисковые запросы для&nbsp;найденных товаров. Проверяем за последние 30 дней.
            </>
          )}
        </div>

        {by === BY_PRODUCTS && (
          <InputList
            min={MIN_AMOUNT}
            placeholder="Введите артикул или ссылку на товар"
            values={products}
            onChange={handleChangeProducts}
            onPaste={handlePasteProducts}
            max={PRODUCTS_LIMIT}
          />
        )}

        {by === BY_PHRASES && (
          <InputList
            min={PHRASES_LIMIT}
            placeholder="Введите поисковой запрос"
            values={phrases}
            onChange={handleChangePhrases}
            onPaste={handlePastePhrases}
            max={PHRASES_MAX_LIMIT}
          />
        )}

        <div className="mt-4 flex space-x-2">
          <div className="relative w-fit">
            <div ref={tooltipNoTariffRef}>
              <ButtonPrimary
                onClick={handleSubmit}
                label="Получить отчёт"
                className="text-base"
                disabled={!values.length}
              />
              {noTariff && (
                <Popover
                  tooltipRef={tooltipNoTariffRef}
                  className="whitespace-nowrap"
                  tooltip="Чтобы воспользоваться сервисами SEO, необходимо оплатить тарифный план"
                />
              )}
            </div>
          </div>
          <button
            className={cn(
              'text-base bg-gray-light border border-gray-250 text-red-bright rounded cursor-pointer py-1 px-4 leading-5 whitespace-nowrap transition-all hover:bg-pink-light hover:border-pink focus:bg-pink-light focus:border-pink disabled:opacity-30 disabled:bg-gray-light disabled:cursor-not-allowed',
            )}
            onClick={handleClear}
            disabled={!values.length}
          >
            Очистить все
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
