import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TariffColumn } from './';
import { useTariffs } from 'context/tariffs.context';
import { usePayment } from 'context/payment.context';
import { tariffsEnum } from 'constants/tariffs';

export const TariffColumnList = ({ modalView }) => {
  const { tariffsData } = useTariffs();
  const { onSelectTariff } = usePayment();

  return (
    <div className="lg:mt-8 lg:flex justify-center">
      {Object.keys(tariffsData).map((key) => (
        <Fragment key={key}>
          <TariffColumn
            item={tariffsData[key]}
            onSubmit={() => onSelectTariff(key)}
            modalView={modalView}
            recommend={+key === +tariffsEnum.PRO}
          />
        </Fragment>
      ))}
    </div>
  );
};

TariffColumnList.propTypes = {
  modalView: PropTypes.bool,
};
