import React, { useState } from 'react';

import Collapse from 'components/Collapse';
import TelegramIcon from 'assets/images/telegram.png';
import Modal from 'components/Modal';
import getQuestions from './questions';

const Faq = () => {
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const handleOpenModal = () => setIsOpenVideoModal(true);
  const handleCloseModal = () => setIsOpenVideoModal(false);
  const questions = getQuestions(handleOpenModal);

  return (
    <>
      <div className="max-w-screen-xl w-full m-auto">
        <h1 className="text-3xl font-medium mb-6 mt-10">Ответы на ваши вопросы</h1>
        {questions.map(({ answer, question }, index) => (
          <Collapse title={question} defaultCollapsed={true} key={index} bordered={true}>
            <div className="pl-5">{answer}</div>
          </Collapse>
        ))}
        <a
          href="https://t.me/we_check"
          className="text-green w-full flex items-center justify-center text-3xl max-w-screen-xl p-7 border border-gray mt-10 mb-20 shadow-sm"
          target="_blank"
          rel="noreferrer"
        >
          <img className="mr-8" alt="Telegram Icon" src={TelegramIcon} />
          Остались вопросы? Задавайте в Telegram ›
        </a>
      </div>
      {isOpenVideoModal && (
        <Modal isOpen closeModal={handleCloseModal} footer={false}>
          <div className="-m-6">
            <div className="relative h-0 pb-[56.25%]">
              <iframe
                src="https://www.youtube.com/embed/2LyPUzyhMe0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Faq;
