import { useEffect, useState } from 'react';
/**
 * @typedef IReturn
 * @returns {[() => string, (data: string) => void]}
 * */
/**
 * Хук для работы с LocalStorage
 *
 * @param {string} key Ключ, по которому будет записано значение
 *
 * @returns {IReturn} Геттер и сеттер для работы с LocalStorage
 * */
export default function useLocalStorage(key) {
  const transformedKey = `WECHECK_${key}`;
  const errorBody = 'useLocalStorage -> error';
  useEffect(() => {
    const isTablesPatched = localStorage.getItem('WECHECK_lastPatchDate') === '02.06.2024';
    if (!isTablesPatched) {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('WECHECK_TABLE-COL-STATE-')) {
          localStorage.removeItem(key);
        }
      });
      localStorage.setItem('WECHECK_lastPatchDate', '02.06.2024');
    }
  }, []);
  const [value, setValue] = useState(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    try {
      const item = window.localStorage.getItem(transformedKey);

      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      console.error(`${errorBody} -> ${error}`);

      return undefined;
    }
  });

  const setter = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(value) : value;

      setValue(valueToStore);

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(transformedKey, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(`${errorBody} -> ${error}`);
    }
  };

  return [value, setter];
}
