const basicConstants = {
  title: 'Basic',
  dataLengthText: 'Данные за последние 30 дней',
  sellers: false,
  sizes: false,
  colors: false,
  warehouses: false,
  newItems: false,
  trends: false,
  priceSegments: false,
  categoryPositions: false,
  searchQueries: false,
  priceChange: true,
  ratingsAndReviews: true,
  extension: true,
  advertisement: false,
  content: true,
  fileExport: false,
};

// const expertConstants = {
//   title: 'Expert',
//   dataLengthText: 'Данные за последние 30 дней',
//   sellers: true,
//   sizes: true,
//   colors: true,
//   warehouses: false,
//   newItems: true,
//   trends: true,
//   priceSegments: true,
//   categoryPositions: true,
//   searchQueries: true,
//   priceChange: true,
//   ratingsAndReviews: true,
//   extension: true,
//   advertisement: false,
//   content: true,
//   fileExport: true,
// };

const proConstants = {
  title: 'PRO',
  dataLengthText: 'Данные за последние 12 месяцев',
  sellers: true,
  sizes: true,
  colors: true,
  warehouses: true,
  newItems: true,
  trends: true,
  priceSegments: true,
  categoryPositions: true,
  searchQueries: true,
  priceChange: true,
  ratingsAndReviews: true,
  extension: true,
  advertisement: true,
  content: true,
  fileExport: true,
};

export const tariffsEnum = {
  BASIC: 1,
  EXPERT: 2,
  PRO: 3,
};

export const tariffsConstants = {
  1: {
    [tariffsEnum.BASIC]: {
      oldPrice: false,
      newPrice: 3900,
      textPrice: 'В МЕСЯЦ',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    // [tariffsEnum.EXPERT]: {
    //   oldPrice: false,
    //   newPrice: 7900,
    //   textPrice: 'В МЕСЯЦ ЗА 1 САЙТ',
    //   buttonText: '🔥  Ваш тариф - Expert',
    //   modalButtonText: '🔥  Выбрать Expert',
    //   ...expertConstants,
    // },
    [tariffsEnum.PRO]: {
      oldPrice: false,
      newPrice: 4900,
      textPrice: 'В МЕСЯЦ',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
  2: {
    [tariffsEnum.BASIC]: {
      oldPrice: 3900,
      newPrice: 3300,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 3 МЕСЯЦА',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    // [tariffsEnum.EXPERT]: {
    //   oldPrice: 10900,
    //   newPrice: 6630,
    //   textPrice: 'В МЕСЯЦ ПРИ ОПЛАТЕ ЗА 3 МЕСЯЦА',
    //   buttonText: '🔥  Ваш тариф - Expert',
    //   modalButtonText: '🔥  Выбрать Expert',
    //   ...expertConstants,
    // },
    [tariffsEnum.PRO]: {
      oldPrice: 4900,
      newPrice: 4300,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 3 МЕСЯЦА',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
  3: {
    [tariffsEnum.BASIC]: {
      oldPrice: 3900,
      newPrice: 2900,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...basicConstants,
    },
    // [tariffsEnum.EXPERT]: {
    //   oldPrice: 10900,
    //   newPrice: 6300,
    //   textPrice: 'В МЕСЯЦ ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
    //   buttonText: '🔥  Ваш тариф - Expert',
    //   modalButtonText: '🔥  Выбрать Expert',
    //   ...expertConstants,
    // },
    [tariffsEnum.PRO]: {
      oldPrice: 4900,
      newPrice: 3900,
      textPrice: 'ПРИ ОПЛАТЕ ЗА 6 МЕСЯЦЕВ',
      buttonText: 'Начать прямо сейчас',
      ...proConstants,
    },
  },
};
