import moment from 'moment';
import propTypes from 'prop-types';
import ArrowDirRight from 'assets/icons/ArrowDirRight';
// import TelegramIcon from 'assets/icons/telegram';
// import VkIcon from 'assets/icons/vk';
import { QuestionIconV2 } from 'assets/icons/QuestionIconV2';
import QuestionIcon from 'assets/icons/QuestionIcon';
import React, { Fragment, useState, useEffect } from 'react';
import { PopoverV2 } from '../PopoverV2';
import cn from 'classnames';
import CrossIcon from 'assets/icons/CrossIcon';
import HorizontalDivider from '../HorizontalDivider';
import useLocalStorage from 'hooks/useLocalStorage';
import Modal from '../Modal';
import { CustomVideoPlayer } from '../CustomVideoPlayer';
import { useUI } from 'context/ui.context';
import { axiosClient } from 'hooks/useAxiosClient';
import { useAuth } from 'context/auth.context';
import LoadingSpin from '../LoadingSpin';
import { CommentIcon } from 'assets/icons/CommentIcon';
import { PlayIcon } from 'assets/icons/PlayIcon';
import Checkbox from 'components/Checkbox';
import { useGlobalDataLoading } from 'hooks/useGlobalDataLoading';
import { MobileInterfaceV2 } from '../MobileInterface/MobileInterface';

/**@type FC<{ children: ReactNode, onClick(): void}>*/
const PopoverClickableSection = ({ onClick, children }) => {
  return (
    <button type="button" onClick={onClick} className="grid gap-2 font-SofiaPro">
      {children}
    </button>
  );
};

PopoverClickableSection.propTypes = {
  children: propTypes.node.isRequired,
  onClick: propTypes.func.isRequired,
};
/**@type FC<{ children: ReactNode }>*/
const PopoverInnerSection = ({ children }) => {
  return (
    <section className="text-sm leading-5 text-gray-300 max-w-xs text-left">{children}</section>
  );
};

PopoverInnerSection.propTypes = {
  children: propTypes.node.isRequired,
};

/**
 * @typedef IProps
 * @property {import("react").CSSProperties} styles Дополнительные стили, которые будут применены к разделителю
 * */
/**
 * @returns {JSX.Element} Разделительная полоса (для пространства между элементами footer'а)
 * @param {IProps} props
 * */
function VerticalDivider({ styles = {} }) {
  return <div className="hidden lg:block bg-[#f0f0f0] h-6 max-h-full w-0.5" style={styles} />;
}

VerticalDivider.propTypes = {
  styles: propTypes.object,
};

const Footer = () => {
  const doNotAskAnymoreId = 'do-not-ask-anymore_input-type-checkbox';

  const { isMobile } = useUI();
  const { user } = useAuth();

  const [lcIsDoNotShowHelpAnymore, setLcIsDoNotShowHelpAnymore] =
    useLocalStorage('isDoNotHelpAnymore');

  const [isPopoverActive, setIsPopoverActive] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isShowConsulting, setIsShowConsulting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loading = useGlobalDataLoading();

  useEffect(() => {
    if (!user || loading) {
      setIsPopoverActive(false);
    } else {
      // fix for modal
      setIsPopoverActive(!lcIsDoNotShowHelpAnymore);
    }
  }, [lcIsDoNotShowHelpAnymore, user, loading]);
  const onIsDoNotShowHelpAnymoreCheckboxClick = () => {
    if (!lcIsDoNotShowHelpAnymore) {
      setIsPopoverActive(false);
    }
    setLcIsDoNotShowHelpAnymore(!lcIsDoNotShowHelpAnymore);
  };

  const onShowVideoClick = () => {
    setIsShowConsulting(false);
    setIsShowVideo(true);
  };

  const onShowConsultingClick = () => {
    const data = {
      name: user.name,
      email: user.email,
      comment: 'from service',
      type: 'demonstration',
    };

    setIsLoading(true);
    axiosClient('/saveform', data, 'POST').then(() => setIsLoading(false));

    setIsShowVideo(false);
    setIsShowConsulting(true);
  };

  const onCloseConsultingClick = () => {
    setIsShowConsulting(false);
  };

  const onQuestionClick = () => {
    setIsPopoverActive((prev) => !prev);
  };

  return (
    <Fragment>
      <footer
        className="
        flex flex-col sm:flex-row shrink-0 items-center gap-2
        bg-gray-50 py-3 px-4 mt-auto
      "
      >
        <div
          className="
          flex w-full flex-col items-center md:w-fit md:items-center md:flex-row gap-2 md:gap-4
          mr-auto text-gray-400
        "
        >
          <div className="group relative">
            <button type="button" className="group relative h-6 w-6 mb-1" onClick={onQuestionClick}>
              <QuestionIconV2
                isActive
                iconActiveColor="#ffffff"
                backgroundActiveColor="#219653"
                className={cn('transition-opacity w-6 h-6 mb-1 absolute inset-0', {
                  ['opacity-100 z-10000']: isPopoverActive,
                  ['opacity-0 -z-10000 group-hover:opacity-100 group-hover:z-10000']:
                    !isPopoverActive,
                })}
              />
              <QuestionIcon
                className={cn('transition-opacity absolute h-6 w-6 mb-1 inset-0', {
                  ['opacity-0 -z-10000']: isPopoverActive,
                  ['opacity-100 z-10000 group-hover:opacity-0 group-hover:-z-10000']:
                    !isPopoverActive,
                })}
              />
            </button>
            {isMobile ? (
              <MobileInterfaceV2
                onPanelClose={() => setIsPopoverActive(false)}
                rootClassName="bottom-0 absolute"
                isActive={isPopoverActive}
                headerText={'Остались вопросы?'}
              >
                <div className="px-5">
                  <div className="py-5 border-b border-[#EBEBEB]">
                    <PopoverClickableSection onClick={onShowVideoClick}>
                      <header className="flex gap-3 items-center">
                        <PlayIcon alt="Посмотрите видео" className="h-4 w-4 fill-[none]" />
                        <p className="text-green font-bold">Посмотрите видео</p>
                      </header>
                      <PopoverInnerSection>
                        Подробно и наглядно покажем про все функции и фишки WeCheck
                      </PopoverInnerSection>
                    </PopoverClickableSection>
                  </div>
                  <div className="py-5 border-b border-[#EBEBEB]">
                    <PopoverClickableSection onClick={onShowConsultingClick}>
                      <header className="flex gap-3 items-center">
                        <CommentIcon
                          alt="Закажите бесплатную консультацию"
                          className="h-4 w-4 fill-[none]"
                        />
                        <p className="text-green font-bold text-left">
                          Закажите бесплатную консультацию
                        </p>
                      </header>
                      <PopoverInnerSection>
                        Расскажем о платформе, как повысить продажи, покажем примеры для вашего
                        бизнеса, ответим на любые вопросы
                      </PopoverInnerSection>
                    </PopoverClickableSection>
                  </div>
                  <div className="py-5">
                    <button
                      className="flex gap-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        onIsDoNotShowHelpAnymoreCheckboxClick();
                      }}
                    >
                      <Checkbox
                        id={doNotAskAnymoreId}
                        onChange={onIsDoNotShowHelpAnymoreCheckboxClick}
                        checked={!!lcIsDoNotShowHelpAnymore}
                        label={''}
                      />
                      <p className="font-SofiaPro text-sm text-gray-400">
                        Больше не показывать это окно
                      </p>
                    </button>
                  </div>
                  <div className="py-4">
                    <button
                      className="w-full bg-green text-white shadow-sm items-center px-3 py-2 rounded"
                      onClick={() => setIsPopoverActive(false)}
                    >
                      Закрыть
                    </button>
                  </div>
                </div>
              </MobileInterfaceV2>
            ) : (
              <PopoverV2
                classes={`${
                  isPopoverActive ? 'visible' : 'hidden'
                } bottom-[150%] -left-36 md:-left-1 !p-6 grid gap-6 w-80 md:w-[25rem]`}
              >
                <button
                  type="button"
                  className=" absolute right-0 top-0 h-8 w-8 flex items-center justify-center"
                  onClick={() => setIsPopoverActive(false)}
                >
                  <CrossIcon className="w-4 h-4" />
                </button>
                <header>
                  <p className="text-black font-SofiaPro font-bold text-xl w-full text-left">
                    Остались вопросы?
                  </p>
                </header>
                <HorizontalDivider />
                <PopoverClickableSection onClick={onShowVideoClick}>
                  <header className="flex gap-3 items-center">
                    <PlayIcon alt="Посмотрите видео" className="h-4 w-4 fill-[none]" />
                    <p className="text-green font-bold">Посмотрите видео</p>
                  </header>
                  <PopoverInnerSection>
                    Подробно и наглядно покажем про все функции и фишки WeCheck
                  </PopoverInnerSection>
                </PopoverClickableSection>
                <HorizontalDivider />
                <PopoverClickableSection onClick={onShowConsultingClick}>
                  <header className="flex gap-3 items-center">
                    <CommentIcon
                      alt="Закажите бесплатную консультацию"
                      className="h-4 w-4 fill-[none]"
                    />
                    <p className="text-green font-bold text-left">
                      Закажите бесплатную консультацию
                    </p>
                  </header>
                  <PopoverInnerSection>
                    Расскажем о платформе, как повысить продажи, покажем примеры для вашего бизнеса,
                    ответим на любые вопросы
                  </PopoverInnerSection>
                </PopoverClickableSection>
                <HorizontalDivider />
                <button
                  className="flex gap-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    onIsDoNotShowHelpAnymoreCheckboxClick();
                  }}
                >
                  <Checkbox
                    id={doNotAskAnymoreId}
                    onChange={onIsDoNotShowHelpAnymoreCheckboxClick}
                    checked={!!lcIsDoNotShowHelpAnymore}
                    label={''}
                  />
                  <p className="font-SofiaPro text-sm text-gray-400">
                    Больше не показывать это окно
                  </p>
                </button>
              </PopoverV2>
            )}
          </div>
          <p>© 2019-{moment().format('YYYY')} WeCheck. Все права защищены</p>
          <VerticalDivider />
          <a
            className="underline"
            href="https://www.web.wecheck.ru/agreement"
            target="_blank"
            rel="noreferrer"
          >
            Договор оферта
          </a>
          <VerticalDivider />
          <a
            className="underline"
            href="https://www.web.wecheck.ru/licence"
            target="_blank"
            rel="noreferrer"
          >
            Политика конфиденциальности
          </a>
          <VerticalDivider />
          <p>
            Пишите по любым вопросам на{' '}
            <a className="underline" href="mailto:hello@wecheck.ru">
              hello@wecheck.ru
            </a>
          </p>
          <VerticalDivider />
          <a
            className="underline flex justify-center items-center gap-1"
            href="https://wecheck.ru/blog/"
            target="_blank"
            rel="noreferrer"
          >
            Блог
            <ArrowDirRight className="fill-gray-400" />
          </a>
        </div>
        <div className="flex items-center shrink-0 gap-2">
          <a
            className="underline text-gray-400"
            href="https://wecheck.ru/"
            target="_blank"
            rel="noreferrer"
          >
            WeCheck.ru
          </a>
          {/*<VerticalDivider />
          <a href="https://t.me/wecheckru" target="_blank" rel="noreferrer">
            <TelegramIcon className="w-[22px] h-[22px]" />
          </a>
          <a href="https://vk.com/wecheck" target="_blank" rel="noreferrer">
            <VkIcon className="w-[22px] h-[22px]" />
          </a>*/}
        </div>
      </footer>
      <Modal
        footer={false}
        width={isMobile ? undefined : 1000}
        title="Видео о WeCheck"
        isOpen={isShowVideo}
        closeModal={() => setIsShowVideo(false)}
      >
        <CustomVideoPlayer videoId={'DJsHWO2kcMU'} maxHeight={480} />
      </Modal>
      <Modal
        width={isMobile ? undefined : 450}
        title={isLoading ? 'Регистрируем Вашу заявку...' : 'Отлично, заявка принята!'}
        isOpen={isShowConsulting}
        closeModal={() => setIsShowConsulting(false)}
        footer={false}
      >
        <LoadingSpin isVisible={isLoading} />
        <article
          className={cn('grid gap-16 content-center justify-center', {
            ['hidden']: isLoading,
          })}
        >
          <header className="font-SofiaPro text-gray-400 text-center text-lg leading-6">
            Скоро мы с вами свяжемся и договоримся о месте и времени проведения консультации
          </header>
          <footer className="w-full">
            <button
              onClick={onCloseConsultingClick}
              type="button"
              className="bg-green w-full py-4 text-center text-xl text-white font-bold rounded-xl"
            >
              Хорошо, жду
            </button>
          </footer>
        </article>
      </Modal>
    </Fragment>
  );
};

export default Footer;
