import React, { memo, Suspense, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DatePicker from 'react-datepicker';

import Popover from 'components/Popover';
import { NOT_AVAILABLE_BECAUSE_TARIFF_DATE, NOT_AVAILABLE_TRIAL } from 'constants/tooltips';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';
import LoadingSpin from 'components/LoadingSpin';
import ButtonArrow from 'components/ButtonArrow';
import { isProductPage, isTrendsPage } from 'utils/pages';
import { getTariffRange } from 'context/filter/filter.context.reducer.methods';
import { declOfNum } from 'utils/client';
import { useAuth } from 'context/auth.context';
import { useAnalyticType } from 'context/analytic.type.context';

const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
  },
};

const getMaxDaysText = ({ step, range }) => {
  if (!step || !range) return null;

  // спрятать подсказку для админа и внутренней аналитики
  // так как у них условно нет ограничений по выбору даты
  if (range >= 3650) {
    return null;
  }

  if (step === range) {
    return `Выберите любой отрезок за\u00A0последние ${range} ${declOfNum(range, [
      'день',
      'дня',
      'дней',
    ])}`;
  }

  return `Выберите любой отрезок длительностью до ${step} ${declOfNum(step, [
    'день',
    'дня',
    'дней',
  ])} за\u00A0последние ${range} ${declOfNum(range, ['день', 'дня', 'дней'])}`;
};

const RangePickerInnerContent = ({ daysRange }) => {
  const { tariffName } = useAuth();
  const isTrends = isTrendsPage();
  const isProduct = isProductPage();
  const { step, range } = getTariffRange({ tariffName, isTrends, isProduct });
  const maxDaysText = getMaxDaysText({ step: daysRange ? daysRange : step, range });

  if (!maxDaysText) {
    return <></>;
  }

  return (
    <div className="grid gap-2 justify-center px-8 pb-6 z-10000">
      <span className="w-full h-[1px] bg-gray-bright rounded" />
      <p className="font-SofiaPro font-normal leading-6 text-center">{maxDaysText}</p>
    </div>
  );
};
RangePickerInnerContent.propTypes = {
  daysRange: PropTypes.number,
};

// eslint-disable-next-line react/display-name
export const RangePicker = memo(
  ({
    startDate,
    endDate,
    onStartChange,
    onEndChange,
    onSubmit,
    disabledSubmit,
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
    checkIsAvailable,
    daysRange,
  }) => {
    const [startVisible, setStartVisible] = useState(false);
    const [endVisible, setEndVisible] = useState(false);
    const { isTrial } = useAuth();

    const tooltipRef = useRef();

    const { filter } = useFilterReducer();
    const { isInner } = useAnalyticType();

    const { checkDatepickerAvailability } = useUserTariffs();

    const { isDatepickerAvailable, isDatepickerAvailableByFilter, isDatepickerAvailableByTariff } =
      checkDatepickerAvailability({ filter, isInner });

    const isAvailable = checkIsAvailable ? checkIsAvailable() : isDatepickerAvailable;
    const commonProps = {
      locale,
      dateFormat: 'dd.MM.yyyy',
      disabled: !isAvailable,
      wrapperClassName: 'calendar-input-wrapper',
      calendarStartDay: 1,
    };

    return (
      <Suspense fallback={<LoadingSpin />}>
        <div
          ref={tooltipRef}
          className={cn('flex relative', {
            ['cursor-not-allowed']: !isAvailable,
          })}
        >
          <DatePicker
            {...commonProps}
            selected={startDate}
            onChange={onStartChange}
            selectsStart
            minDate={minStartDate}
            maxDate={maxStartDate}
            placeholderText="Начальная дата"
            className={cn(
              'calendar-input calendar-input--left button-group__item button-group__item--first',
              {
                ['button-group__item--disabled']: !isAvailable,
              },
            )}
            onCalendarOpen={() => setTimeout(() => setStartVisible(true), 10)}
            onCalendarClose={() => setStartVisible(false)}
            calendarClassName={cn('float-none calendar-input-overlay', {
              ['calendar-input-overlay__show']: startVisible,
              ['calendar-input-overlay__hide']: !startVisible,
            })}
          >
            <RangePickerInnerContent daysRange={daysRange} />
          </DatePicker>
          <DatePicker
            {...commonProps}
            selected={endDate}
            onChange={onEndChange}
            selectsEnd
            minDate={minEndDate}
            maxDate={maxEndDate}
            placeholderText="Конечная дата"
            className={cn(
              'calendar-input calendar-input--right button-group__item button-group__item--last',
              {
                ['button-group__item--disabled']: !isAvailable,
              },
            )}
            onCalendarOpen={() => setTimeout(() => setEndVisible(true), 10)}
            onCalendarClose={() => setEndVisible(false)}
            calendarClassName={cn('float-none calendar-input-overlay', {
              ['calendar-input-overlay__show']: endVisible,
              ['calendar-input-overlay__hide']: !endVisible,
            })}
          >
            <RangePickerInnerContent daysRange={daysRange} />
          </DatePicker>

          <div className="absolute right-[5px] top-[5px] z-30">
            <ButtonArrow onClick={onSubmit} disabled={disabledSubmit || !isAvailable} />
          </div>
        </div>
        {!isAvailable && (
          <Popover
            tooltip={
              !isDatepickerAvailableByTariff
                ? isTrial
                  ? NOT_AVAILABLE_TRIAL
                  : NOT_AVAILABLE_BECAUSE_TARIFF_DATE
                : !isDatepickerAvailableByFilter
                ? 'Выберите категорию, бренд или продавца для изменения дат'
                : ''
            }
            tooltipRef={tooltipRef}
          />
        )}
      </Suspense>
    );
  },
);
RangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  minStartDate: PropTypes.instanceOf(Date).isRequired,
  maxStartDate: PropTypes.instanceOf(Date).isRequired,
  onStartChange: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  minEndDate: PropTypes.instanceOf(Date).isRequired,
  maxEndDate: PropTypes.instanceOf(Date).isRequired,
  onEndChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabledSubmit: PropTypes.bool.isRequired,
  fromPanel: PropTypes.bool,
  checkIsAvailable: PropTypes.func,
  daysRange: PropTypes.number,
};
