import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import crossImage from 'assets/images/crossTariffs.svg';
import checkImage from 'assets/images/checkedTariffs.svg';

import Popover from 'components/Popover';
import { TariffItemShape } from './';
import { useUI } from 'context/ui.context';

const Item = ({ text, /*tooltip,*/ check, className = 'mb-3' }) => {
  // const buttonRef = useRef();

  return (
    <div className={cn('flex text-base', className)}>
      <div className={!check ? 'text-gray-400' : ''}>{text}</div>
      <div>
        {/*<button ref={buttonRef} className="ml-3">*/}
        {/*  <img src={questionImage} alt="Question Icon" className="w-5" />*/}
        {/*</button>*/}
        {/*<Popover*/}
        {/*  tooltipRef={buttonRef}*/}
        {/*  tooltip={tooltip || ''}*/}
        {/*  bgColor="gray-900"*/}
        {/*  color="white"*/}
        {/*  arrow={true}*/}
        {/*/>*/}
      </div>
      <div className="border-dashed border-b flex-1" />
      <img
        alt={check ? 'Check Icon' : 'Cross Icon'}
        className="w-5"
        src={check ? checkImage : crossImage}
      />
    </div>
  );
};

Item.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  check: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const MobileItem = ({ text, tooltip, className = 'mb-3', check }) => {
  const buttonRef = useRef();
  // const checkBasic = checkAll || tariffsData[tariffsEnum.BASIC][field];
  // const checkPro = checkAll || tariffsData[tariffsEnum.PRO][field];
  // const resultCheck = item.title === 'Basic' ? checkBasic : checkPro;
  // console.log(item.title, field, resultCheck)
  return (
    <div
      className={cn('grid text-base', className)}
      style={{
        gridTemplateColumns: '10fr 1fr',
      }}
    >
      <div className="flex whitespace-nowrap">
        {text}
        <div className="flex items-center">
          <button ref={buttonRef} className="ml-1.5">
            <svg
              width="20"
              height="20"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.4">
                <path
                  d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                  fill="white"
                  stroke="#6E6E6E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.60938 5.54998C5.76219 5.11556 6.06382 4.74925 6.46085 4.51592C6.85787 4.28258 7.32466 4.19729 7.77854 4.27514C8.23242 4.353 8.64411 4.58897 8.94068 4.94127C9.23724 5.29358 9.39956 5.73947 9.39887 6.19998C9.39887 7.49998 7.44887 8.14998 7.44887 8.14998"
                  stroke="#6E6E6E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 10.75H7.507"
                  stroke="#6E6E6E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </button>
          <Popover
            tooltipRef={buttonRef}
            tooltip={tooltip || ''}
            bgColor="gray-900"
            color="white"
            arrow={true}
          />
        </div>
        <div className="border-dashed border-b flex-1" />
      </div>
      <div className="flex justify-end">
        <img
          alt={check ? 'Check Icon' : 'Cross Icon'}
          className="w-5"
          src={check ? checkImage : crossImage}
        />
      </div>
    </div>
  );
};

MobileItem.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  field: PropTypes.string,
  checkAll: PropTypes.bool,
  className: PropTypes.string,
  check: PropTypes.any,
};

const Row = ({ text, tooltip, check, checkAll, className = 'mb-3' }) => {
  const { isMobile } = useUI();

  return (
    <>
      {isMobile ? (
        <MobileItem
          text={text}
          checkAll={checkAll}
          className={className}
          tooltip={tooltip}
          check={check}
        />
      ) : (
        <Item text={text} check={check} className={className} tooltip={tooltip} />
      )}
    </>
  );
};

Row.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  check: PropTypes.bool.isRequired,
  checkAll: PropTypes.bool,
  className: PropTypes.string,
  item: PropTypes.any,
};

export const List = ({ item }) => {
  return (
    <>
      <h3 className="mt-5 text-base mb-3 font-bold">Аналитика продаж:</h3>

      <Row
        text="Товаров"
        tooltip="Более 30 параметров для анализа по каждому товару"
        check
        checkAll
        item={item}
      />
      <Row
        text="Категорий"
        tooltip="Объемы продаж, потенциал, конкурентность и перспективы в каждой категории маркетплейса"
        check
        checkAll
        item={item}
      />
      <Row
        text="Брендов"
        tooltip="Аналитика по брендам. Изучайте с какими брендами работают селлеры"
        check
        checkAll
        item={item}
      />
      <Row
        text="Продавцов"
        tooltip="Покажем лучших продавцов в каждой категории. Изучайте их стратегии и опережайте"
        check={item.sellers}
        item={item}
      />
      <Row
        text="Размеров"
        tooltip="Аналитика продаж и остатков по размерам для лучшего планирования закупок"
        check={item.sizes}
        item={item}
      />
      <Row
        text="Цветов"
        tooltip="Аналитика продаж и остатков по цветам для лучшего планирования закупок"
        check={item.colors}
        item={item}
      />
      <Row
        text="Складов"
        tooltip="Продажи и остатки продавцов по складам и регионам"
        check={item.warehouses}
        className="mb-8"
        item={item}
      />
      <Row
        text="Новинки"
        tooltip="Список и аналитика продаж новинок за выбранный период"
        check={item.newItems}
        className="mb-8"
        item={item}
      />
      <Row
        text="Тренды"
        tooltip="Аналитика динамики продаж, новых продавцов в категории, конкурентности и изменений среднего чек"
        check={item.trends}
        item={item}
      />
      <Row
        text="Ценовая сегментация"
        tooltip="Аналитика по ценовым сегментам. WeCheck покажет, товары какой цены имеют лучший потенциал продаж"
        check={item.priceSegments}
        item={item}
        className="mb-8"
      />
      <Row
        text="Позиция в категориях"
        tooltip="Аналитика позиции карточек товаров в каждой категории"
        check={item.categoryPositions}
        item={item}
      />
      <Row
        text="Поисковые запросы"
        tooltip="По каким запросам находят ваши товары и товары конкурентов. Позиции и динамика"
        check={item.searchQueries}
        item={item}
      />
      <Row
        text="Изменение цен"
        tooltip="Аналитика изменения цен у товаров"
        check={item.priceChange}
        item={item}
      />
      <Row
        text="Рейтинги и отзывы"
        tooltip="Покажем как рейтинги и отзывы влияют на продажи"
        check={item.ratingsAndReviews}
        className="mb-8"
        item={item}
      />
      <Row
        text="Расширение для браузера"
        tooltip="Аналитика продаж прямо на сайте маркетплейса. Расширение для браузера Chrome"
        check={item.extension}
        className="mb-8"
        item={item}
      />
      <Row
        text="Аналитика рекламы"
        tooltip="Список товаров с рекламой в каждой категории по дням"
        check={item.advertisement}
        className="mb-8"
        item={item}
      />
      <Row text="Аналитика контента" check={item.content} className="mb-8" item={item} />
      <Row text="Экспорт в файлы" check={item.fileExport} className="mb-8" item={item} />
    </>
  );
};
List.propTypes = {
  item: TariffItemShape,
  items: PropTypes.object,
  disabledButton: PropTypes.bool,
  recommend: PropTypes.bool,
  onClick: PropTypes.func,
  modalView: PropTypes.bool,
};
