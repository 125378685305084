import PropTypes from 'prop-types';
import ButtonPrimary from '../ButtonPrimary';
import RefreshIcon from 'assets/icons/RefreshIcon';
import cn from 'classnames';
import EmptyPhoto from 'assets/icons/EmptyPhoto';
import classNames from 'classnames';
import LinkIcon from 'assets/icons/LinkIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useQuerySeo } from 'hooks/useFetchSeo';
import { SEO_MONITORING_PHRASES, TABLE_TYPE } from '../../constants';
import Table from '../Table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import DownIcon from 'assets/icons/DownIcon';

const MonitoringResult = ({ data = [], handleNewMonitoring, phrases, isLoading, isEntering }) => {
  const { filter } = useFilterReducer();
  const [activeTab, setActiveTab] = useState(data[0]?.id);
  const [hoveredId, setHoveredId] = useState();
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const scrollRef = useRef();
  const {
    data: dataPhrases = [],
    isLoading: phrasesLoading,
    isFetching: phrasesFetching,
  } = useQuerySeo({
    type: SEO_MONITORING_PHRASES,
    enabled: filter.date.length !== 0 && !!activeTab,
    filter: {
      platform: '2',
      products: [data.find((el) => el.id === activeTab)?.link],
      date: filter.date,
    },
    restricts: {
      search: phrases.filter((el) => el),
      is_entering: isEntering,
    },
  });
  useEffect(() => {
    if (scrollRef.current) {
      setShowScrollButtons(scrollRef.current.scrollWidth > scrollRef.current.offsetWidth);
    }
  }, [scrollRef.current?.scrollWidth]);
  useEffect(() => {
    setActiveTab(data[0]?.id);
  }, [data]);
  const getPhrasesData = () => {
    return dataPhrases.map(({ dates, ...other }) => {
      const resultData = { dates, ...other };
      dates.forEach((el, index) => {
        const { sort_position, diff } = el;
        resultData[`${el.created_at}_sort_position`] = sort_position;
        resultData[`${el.created_at}_diff`] = index === 0 ? '-' : diff;
      });
      return resultData;
    });
  };
  return (
    <>
      <div className="bg-[#F0F0F0] py-4 px-8 border-t border-[#E8E8E8]">
        <div className="flex">
          <ButtonPrimary
            onClick={handleNewMonitoring}
            label={
              <div className="flex space-x-2 items-center py-[3px]">
                <RefreshIcon width={16} height={16} />
                <span className="block">Новый запрос</span>
              </div>
            }
            className="text-base group !pl-[14px]"
          />
        </div>
      </div>
      <div className="w-full pt-4 bg-white border-t border-[#E8E8E8]">
        <div className="flex justify-between mb-3 px-8 ">
          <span className="text-lg leading-8 block text-[#333D37] font-bold">{`Товаров: ${data.length}`}</span>
          {showScrollButtons && (
            <div className="flex space-x-1">
              <button
                className="border border-[#D9D9D9] h-8 w-8 flex items-center justify-center rounded hover:!border-green"
                onClick={() =>
                  scrollRef.current.scrollTo({
                    left: scrollRef.current.scrollLeft - scrollRef.current.offsetWidth + 50,
                    behavior: 'smooth',
                  })
                }
              >
                <DownIcon height={16} width={16} className="rotate-90" />
              </button>
              <button
                className="border border-[#D9D9D9] h-8 w-8 flex items-center justify-center rounded hover:!border-green"
                onClick={() =>
                  scrollRef.current.scrollTo({
                    left: scrollRef.current.scrollLeft + scrollRef.current.offsetWidth - 50,
                    behavior: 'smooth',
                  })
                }
              >
                <DownIcon height={16} width={16} className="-rotate-90" />
              </button>
            </div>
          )}
        </div>
        <div className="overflow-x-auto px-8" ref={scrollRef}>
          <div className="flex space-x-3 pb-4">
            {data.map((product) => {
              const { external_id, id, image, link, name } = product;
              return (
                <div
                  key={id}
                  className={cn(
                    'p-3 rounded-lg border border-[#D9D9D9] min-w-[196px] w-[196px] cursor-pointer',
                    {
                      'border-2 !border-green': id === activeTab,
                      'border-[#858585]': hoveredId === id,
                    },
                  )}
                  onClick={() => setActiveTab(id)}
                  onMouseEnter={() => setHoveredId(id)}
                  onMouseLeave={() => setHoveredId(null)}
                >
                  <div className="flex min-h-[51px] justify-between mb-4">
                    <div className="block w-[124px] leading-[17px] text-black text-ellipsis line-clamp-3">
                      {name}
                    </div>
                    {image ? (
                      <img
                        src={image}
                        className="h-10 w-10 border border-solid border-grey-light rounded"
                      />
                    ) : (
                      <div className="flex items-center pt-[2px]">
                        <EmptyPhoto width={40} height={40} />
                      </div>
                    )}
                  </div>
                  <div className={'flex'}>
                    <span className="block text-xs leading-[14px] text-[#858585]">Артикул</span>
                    <div className="flex grow border-b border-dashed border-[#D9D9D9] mx-1 relative -top-[2px]" />
                    <span className="block text-xs leading-[14px] text-black">{external_id}</span>
                  </div>
                  <div className={'flex mt-2'}>
                    <span className="block text-xs leading-[14px] text-[#858585]">URL</span>
                    <div className="flex grow border-b border-dashed border-[#D9D9D9] mx-1 relative -top-[2px]" />
                    <a
                      href={link}
                      rel="noreferrer"
                      target="_blank"
                      className={classNames(
                        'h-[14px] cursor-pointer border border-gray-250 rounded text-xs leading-none px-1 inline-block transition-all hover:text-white',
                        {
                          ['text-wb hover:border-wb hover:bg-wb']: true,
                        },
                      )}
                    >
                      <span className="inline-block text-[10px] leading-[10px]">{'WB'}</span>
                      <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle relative -top-[1px]" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Table
        data={getPhrasesData()}
        type={TABLE_TYPE.SEO_MONITORING_RESULTS}
        isLoading={isLoading || phrasesLoading || phrasesFetching}
        noRowsText={`По вашему запросу нет данных.`}
      />
    </>
  );
};

MonitoringResult.propTypes = {
  data: PropTypes.any,
  handleNewMonitoring: PropTypes.func,
  isLoading: PropTypes.bool,
  isEntering: PropTypes.bool,
  phrases: PropTypes.any,
  products: PropTypes.any,
};

export default MonitoringResult;
