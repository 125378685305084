import cn from 'classnames';
import PropTypes from 'prop-types';
import Fire from 'assets/images/fire.png';

import { List } from './';

export const TariffItemShape = PropTypes.shape({
  oldPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  newPrice: PropTypes.number,
  textPrice: PropTypes.string,
  title: PropTypes.string,
  dataLengthText: PropTypes.string,
  sellers: PropTypes.bool,
  sizes: PropTypes.bool,
  colors: PropTypes.bool,
  warehouses: PropTypes.bool,
  newItems: PropTypes.bool,
  trends: PropTypes.bool,
  priceSegments: PropTypes.bool,
  categoryPositions: PropTypes.bool,
  searchQueries: PropTypes.bool,
  priceChange: PropTypes.bool,
  ratingsAndReviews: PropTypes.bool,
  extension: PropTypes.bool,
  advertisement: PropTypes.bool,
  buttonText: PropTypes.string,
  modalButtonText: PropTypes.string,
  fileExport: PropTypes.bool,
  content: PropTypes.bool,
}).isRequired;

export const getFormattedPrice = (price) => price.toLocaleString();

export const TariffColumn = ({ item, recommend, modalView, onSubmit }) => {
  return (
    <div
      className={cn('w-full lg:w-1/3 flex-row text-xs px-2 lg:px-10', {
        'py-8': recommend,
        'py-12': !recommend,
      })}
      style={{
        boxShadow: recommend ? '0px 4px 80px rgba(39, 150, 83, 0.12)' : undefined,
        borderRadius: recommend ? '32px' : undefined,
      }}
    >
      {recommend && (
        <div className="text-green font-normal leading-[180%] tracking-[.10em]">
          {' '}
          РЕКОМЕНДУЕМЫЙ{' '}
        </div>
      )}
      <div className="text-sm text-red text-right line-through h-5">
        {' '}
        {item.oldPrice ? getFormattedPrice(item.oldPrice) : ''}
      </div>
      <h2 className="flex text-2xl font-bold">
        {item.title}
        <div className="border-dashed border-b flex-1" />
        <div className="text-green font-bold text-xl">{getFormattedPrice(item.newPrice)}</div>
      </h2>
      <div className="text-gray-400 font-light text-right my-3 text-xs tracking-[.10em]">
        {' '}
        {item.textPrice}
      </div>

      <div className="border text-center py-4 text-base rounded-lg mb-7">{item.dataLengthText}</div>

      <List item={item} />

      <button
        className={cn(
          'mt-5 ml-3y-2 primary-button w-full p-3 rounded-full text-xl flex items-center justify-center',
          {
            'bg-white shadow-[0_8px_60px_rgb(235,55,0,0.3)]': recommend,
            'border border-green text-green': !recommend,
          },
        )}
        onClick={onSubmit}
      >
        {recommend && <img src={Fire} alt="no img" className="w-5 h-5 mr-2.5 -mt-0.5" />}
        {modalView ? item.modalButtonText ?? item.buttonText : item.buttonText}
        {/*{getFormattedPrice(item.newPrice)}*/}
      </button>

      <div className="text-base text-gray-400 text-center my-4">
        Если не понравится, вернем деньги
      </div>
    </div>
  );
};
TariffColumn.propTypes = {
  item: TariffItemShape,
  disabledButton: PropTypes.bool,
  recommend: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  modalView: PropTypes.bool,
};
