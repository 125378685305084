import PropTypes from 'prop-types';

const ArrowRightIcon = ({ width = '10', height = '11' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 11"
      fill="none"
    >
      <path
        d="M3 9.5L7 5.5L3 1.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
};

export { ArrowRightIcon };
