import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

export const PeriodButton = ({ selected, onClick, children, className }) => (
  <button
    className={cn(
      'relative px-3 lg:px-6 py-3 mx-1 text-green flex text-sm lg:text-md whitespace-nowrap justify-center border lg:py-3 lg:text-lg',
      {
        'bg-green text-white': selected,
        [className]: className,
      },
    )}
    style={{
      borderRadius: '120px',
    }}
    onClick={onClick}
  >
    {children}
  </button>
);

PeriodButton.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
