import React from 'react';
import playImg from 'assets/images/play.png';

const getQuestions = (openVideoModal) => [
  {
    question: 'Откуда вы знаете продажи каждого товара на wildberries?',
    answer: (
      <div>
        Мы ежедневно сканируем wildberries и сохраняем остатки каждого товара. Разница между
        дневными остатками и есть продажи. <br />
        Например:
        <br />
        Наши роботы зашли 01.01.2021 в карточку товара и зафиксировали остаток 147 шт.
        <br />
        На следующий день в этой же карточке зафиксировали остаток 135 шт.
        <br />
        Мы считаем, что продажи за 01.01.2021 составили 147-135 =12 шт.
      </div>
    ),
  },
  {
    question: 'Какие данные и отчеты доступны в платформе wecheck?',
    answer: (
      <div>
        Все отчеты на платформе wecheck доступны в разрезе категорий, брендов, продавцов или
        товаров.
        <br />
        Применяйте любые сочетания фильтров, чтобы изучить данные в нужном вам разрезе
        <br />
        Доступные данные: <br />
        <br />
        <b>Продажи</b> - количество продаж в штуках и рублях, а также их доли относительно
        wildberries
        <br />
        <b>Бренды</b> - количество брендов у которых хоть один товар был в наличии
        <br />
        <b>Продавцы</b> - количество продавцов у которых хоть один товар был в наличии
        <br />
        <b>SKU</b> - кол-во уникальных товаров, зафиксированных на площадке
        <br />
        <b>SKU c продажами</b> - кол-во уникальных товаров, у которых была хоть одна продажа
        <br />
        <b>Средний чек </b>- средняя стоимость проданных товаров
        <br />
        <b>Средняя цена</b> - средняя цена товаров находящихся в наличии
        <br />
        <b>Рейтинг</b> - средний рейтинг товаров
        <br />
        <b>Отзывы</b> - количество отзывов
        <br />
        <b>Наличие</b> - сумма остатков всех товаров
        <br />
        <b>Листинг</b> - позиция товара в каталоге
        <br />
        <br />
        Все данные представлены в виде удобных графиков и сводных таблиц.
      </div>
    ),
  },
  {
    question: 'Как часто обновляются данные?',
    answer: <div>Данные на платформе wecheck обновляются раз в сутки</div>,
  },
  {
    question: 'На какой период можно оплатить подписку?',
    answer: (
      <div>
        Минимальный срок подписки - месяц
        <br />
        При оплате от 3ех месяцев предусмотрены дополнительные скидки. Скидки рассчитываются
        автоматически в личном кабинете
      </div>
    ),
  },
  {
    question: 'По каким регионам вы собираете информацию?',
    answer: (
      <div>
        Остатки: мы сканируем информацию со складов всех регионов <br />
        Листинг: информацию по позиции товара в каталоге мы сканируем для города Москвы в ночное
        время
        <br />
      </div>
    ),
  },
  {
    question: 'Какая погрешность в ваших данных?',
    answer: (
      <div>
        Погрешность зависит от типа склада с которого осуществляются продажи.
        <br />
        На wildberries продажи возможны с двух типов склада:
        <br />
        Со склада wildberries (FBO)
        <br />
        Cо склада продавца (FBS)
        <br />
        <br />
        Данные о продажах со склада FBO достаточно точные. Погрешность составляет ±10%
        <br />
        Данные о продажах со склада FBS менее точные. Это связано с тем, что продавцы произвольно
        могут менять остатки.
        <br />
        Наш сервис частично умеет определять изменения остатков не связанных с продажами и не
        учитывать подобные. Но все же отклонения от &quot;реальных&quot; продаж при отгрузках с
        этого склада больше чем при FBO
        <br />
        <br />
        Вы можете самостоятельно выбирать с какого склада просматривать продажи. FBO, FBS или оба
        склада
      </div>
    ),
  },
  {
    question: 'Как я могу применять ваши данные?',
    answer: (
      <div>
        Данные платформы wecheck удобно использовать для поиска новых ниш.
        <br />
        Находите товары и категории продажи которых активно растут. Находите ниши с наименьшей
        конкуренцией.
        <br />
        Изучайте как изменяется ассортимент у конкретных продавцов. Анализируйте популярность
        брендов.
        <br />
        Принимайте решения о закупке товаров на основании продаж у конкурентов.
        <br />
        Механик применения наших данных очень много.
        <br />
      </div>
    ),
  },
  {
    question:
      'Можно ли размещать информацию полученную на платформе wecheck на сторонних ресурсах?',
    answer: (
      <div>
        Любые публикации с использованием информации полученной на платформе wecheck, разрешаются
        только после согласования с администрацией сайта и ссылкой на источник wecheck.ru
        <br />
        По всем вопросам пишите на hello@wecheck.ru
      </div>
    ),
  },
  {
    question: 'Можно ли одновременно пользоваться платформой wecheck с нескольких компьютеров?',
    answer: (
      <div>
        Одновременная работа с двух устройств невозможна. При этом вы можете пользоваться сервисом с
        разных устройств но не одновременно.
      </div>
    ),
  },
  {
    question: 'Мне не хватает данных, сможете добавить нужную мне информацию в отчет?',
    answer: (
      <div>
        Мы постоянно совершенствуем наш сервис. Если для вашей работы не хватает каких-то
        показателей, пожалуйста напишите нам и мы оценим возможность доработки нашей платформы. Все
        полезные предложения мы внедряем очень быстро Пишите на hello@wecheck.ru
      </div>
    ),
  },
  {
    question: 'За какой период у вас есть данные?',
    answer: <div>Данные в платформе wecheck доступны с 24.10.2020</div>,
  },
  {
    question: 'Как получить демо-доступ?',
    answer: (
      <div>
        Для ознакомления с платформой мы выдаем демо доступ на 24 часа.
        <br />
        Если после самостоятельного изучения у вас останутся вопросы, мы проведем для вас онлайн
        консультацию по работе платформы
        <br />
        Ознакомиться с возможностями платформы wecheck вы также можете просмотрев видео
        <br />
      </div>
    ),
  },
  {
    question: 'Какие способы оплаты вы принимаете?',
    answer: (
      <div>
        Мы принимаем все типы банковских карт, ApplePay, GooglePay, Юmoney, QIWI, WebMoney. <br />
        Вы также можете оплатить счет банковским переводом от юр.лица или ИП
        <br />
        Счет выставляется без НДС
        <br />
        Оплатить подписку или сформировать счет можно в личном кабинете
        <br />
      </div>
    ),
  },
  {
    question: 'Можно ли добавить в отчет нужный мне интернет-магазин?',
    answer: (
      <div>
        Да, мы можем собирать для вас данные любого интернет-магазина.
        <br />
        По всем вопросам пишите на hello@wecheck.ru
      </div>
    ),
  },
  {
    question: 'У вас есть видео с демонстраницей работы сервиса?',
    answer: (
      <div>
        Да, по ссылке видео в котором демонстрируется основной функционал платформы.
        <button
          className="mb-2 px-7 py-2 rounded-full flex items-center mt-5 shadow border border-gray-250"
          onClick={() => openVideoModal()}
        >
          <img src={playImg} className="w-10 mr-5" alt="Play Icon" />
          <div className="text-xl">Видео как работает wecheck</div>
        </button>
      </div>
    ),
  },
  {
    question: 'Что такое тип склада и почему это важно?',
    answer: (
      <div>
        Существует два основных типа склада. Ключевое различие в точности данных о продажах.
        <br />
        <br />
        FBO - продажи со склада маркетплейса. Данные точные.
        <br />
        FBS - продажи со склада продавца. Данные с погрешностью.
        <br />
        Чаще всего продажи со склада FBS завышены. Продавцы могут произвольно изменять остатки на
        своем складе. Или, например, передавать остатки связанные со своим офлайн магазином или
        оптовым отделом продаж. Изменение остатка в офлайн магазине продавца приведет к изменению
        остатка на маркетплейсе и наша платформа зафиксирует продажу.
      </div>
    ),
  },
];

export default getQuestions;
