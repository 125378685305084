import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { useActiveNavLink } from 'hooks/useActiveNavLink';
import {
  PAGE_PROFILE,
  PAGE_PROFILE_SUBSCRIPTIONS,
  PAGE_PROFILE_TARIFFS,
  PAGE_PROFILE_REFERRALS,
} from 'constants/pages';

const tabs = [
  {
    link: PAGE_PROFILE_SUBSCRIPTIONS,
    title: 'Подписки',
  },
  {
    link: PAGE_PROFILE,
    title: 'Профиль',
  },
  {
    link: PAGE_PROFILE_REFERRALS,
    title: 'Реферальная программа',
  },
  {
    link: PAGE_PROFILE_TARIFFS,
    title: 'Тарифы',
  },
];

const Tab = ({ title, link }) => (
  <Link
    className={cn('pb-4 px-2 mr-2 mb-[-1px] text-gray-400 text-xs lg:text-sm', {
      'border-b-2 border-green text-green-500': useActiveNavLink(link),
    })}
    key={title}
    to={link}
  >
    {title}
  </Link>
);

Tab.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
};

const ProfileTabs = () => (
  <div className="-mx-3 px-3 lg:mx-0 lg:px-0 mt-4 flex border-b overflow-x-auto overflow-y-hidden">
    {tabs.map((tab) => (
      <Tab key={tab.link} link={tab.link} title={tab.title} />
    ))}
  </div>
);

export default ProfileTabs;
