import React, { useState, useRef } from 'react';
import cn from 'classnames';
import ChatsOpenerIcon from 'assets/icons/ChatsOpenerIcon';
import HelpModal from '../HelpModal';
import useClickOutside from 'hooks/useClickOutside';
import WhatsAppIcon from 'assets/icons/WhatsAppIcon';
import NewTelegramIcon from 'assets/icons/NewTelegramIcon';
import NewVkIcon from 'assets/icons/NewVkIcon';
import ChatWecheckIcon from 'assets/icons/ChatWecheckIcon';
import { useUI } from 'context/ui.context';
import { MobileInterfaceV2 } from '../MobileInterface/MobileInterface';
import QuestionIcon from '../../assets/icons/QuestionIcon';
// import { ChatsIcon } from '../../assets/icons/ChatsIcon';
import { ArrowRightIcon } from '../../assets/icons/ArrowRightIcon';
import { useVideoLessons } from '../../context/video.lessons.context';
import VideoIcon from '../../assets/icons/VideoIcon';
import Calendar from '../../assets/icons/Calendar';
import { usePayment } from '../../context/payment.context';
import { axiosClient } from '../../hooks/useAxiosClient';
import { useAuth } from '../../context/auth.context';

export const ChatLinks = [
  {
    Icon: WhatsAppIcon,
    link: 'https://wa.me/79522379685',
    tooltip: 'Написать в WhatsApp',
    id: 'chat_whatsapp_toggler',
  },
  {
    Icon: NewTelegramIcon,
    link: 'https://t.me/we_check',
    tooltip: 'Написать в Telegram',
    id: 'chat_telegram_toggler',
  },
  {
    Icon: NewVkIcon,
    link: 'https://vk.com/im?media=&sel=-211040288',
    tooltip: 'Написать в VK',
    id: 'chat_vk_toggler',
  },
  {
    Icon: ChatWecheckIcon,
    iconClassName: 'py-1 pl-[1px] text-green',
    iconSize: 28,
    tooltip: 'Написать в чат WeCheck',
    id: 'chat_wecheck_toggler',
    onClick: (callBack) => {
      window.jivo_api.open();
      callBack && callBack();
    },
  },
];

export const DesktopChats = () => {
  const [isHover, setIsHover] = useState(false);
  const { setIsModalOpen } = useVideoLessons();
  const { setIsShowConsulting, setIsConsultLoading } = usePayment();
  const { user, isAdmin } = useAuth();
  return (
    <div
      className="flex items-center relative h-full cursor-pointer pl-4"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span className={cn('block text-[#858585]', { '!text-black': isHover })}>
        <QuestionIcon width={'16'} height={'16'} />
      </span>
      <div className="h-8 mx-4 bg-[#E3E3E3] w-px" />
      {isHover && (
        <div
          className="absolute bg-white rounded-[8px] flex flex-col top-[62px] right-0 py-[6px]"
          style={{ boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)' }}
        >
          {/*<button className="flex items-center px-4 py-[2px] space-x-2 w-full">
            <div
              key={ChatLinks[3].id}
              onClick={(e) => {
                e.preventDefault();
                ChatLinks[3].onClick();
              }}
              className="transition-all flex items-center group cursor-pointer w-full"
            >
              <ChatsIcon
                width={'24'}
                height={'24'}
                className={cn(ChatLinks[3].iconClassName, 'text-black group-hover:text-green')}
              />
              <span className="font-medium lg:font-normal mt-0.5 lg:mt-0 text-base lg:text-sm text-black block ml-2 whitespace-nowrap group-hover:text-green self-center relative -left-1">
                {ChatLinks[3].tooltip}
              </span>
            </div>
          </button>
          <div className="h-px mx-4 bg-[#E3E3E3] my-1" />
          {ChatLinks.slice(0, -1).map((el) => {
            const { id, link, tooltip, Icon, iconClassName = '', iconSize = 20 } = el;
            return (
              <a
                key={id}
                target="_blank"
                rel="noreferrer"
                href={link}
                className="transition-all flex items-center group cursor-pointer px-4 py-[2px] space-x-2 h-8 justify-between"
              >
                <div className="flex items-center">
                  <Icon width={iconSize} height={iconSize} className={iconClassName} />
                  <span className="font-medium lg:font-normal mt-0.5 lg:mt-0 text-base lg:text-sm text-black block ml-2 whitespace-nowrap group-hover:text-green self-center">
                    {tooltip}
                  </span>
                </div>
                <span className={cn('block text-[#D9D9D9] group-hover:text-green')}>
                  <ArrowRightIcon width={'18'} height={'18'} />
                </span>
              </a>
            );
          })}
          <div className="h-px mx-4 bg-[#E3E3E3] my-1" />*/}
          <a
            key={'/faq'}
            target="_blank"
            rel="noreferrer"
            href={'/faq'}
            className="transition-all flex items-center group cursor-pointer pr-4 pl-[18px] py-[2px] space-x-2 h-8 justify-between"
          >
            <div className="flex items-center">
              <QuestionIcon width={18} height={18} />
              <span className="font-medium lg:font-normal mt-0.5 lg:mt-0 text-base lg:text-sm text-black block ml-2 whitespace-nowrap group-hover:text-green self-center">
                {'FAQ'}
              </span>
            </div>
            <span className={cn('block text-[#D9D9D9] group-hover:text-green')}>
              <ArrowRightIcon width={'18'} height={'18'} />
            </span>
          </a>
          {isAdmin && (
            <button className="flex items-center px-4 py-[2px] space-x-2 h-8 ">
              <div
                key={'videolessons'}
                onClick={() => setIsModalOpen(true)}
                className="transition-all flex items-center group cursor-pointer justify-between w-full"
              >
                <div className="flex items-center group-hover:text-green">
                  <VideoIcon width={'20'} height={'20'} />
                  <span className="font-medium lg:font-normal mt-0.5 lg:mt-0 text-base lg:text-sm text-black block ml-2 whitespace-nowrap group-hover:text-green self-center relative">
                    {'Видеоинструкция'}
                  </span>
                </div>
                <span className={cn('block text-[#D9D9D9] group-hover:text-green')}>
                  <ArrowRightIcon width={'18'} height={'18'} />
                </span>
              </div>
            </button>
          )}
          <div className="h-px mx-4 bg-[#E3E3E3] my-1" />
          <button className="flex items-center px-4 py-[2px] space-x-2 h-8 ">
            <div
              key={'consult'}
              onClick={async () => {
                const data = {
                  name: user.name,
                  email: user.email,
                  comment: 'from service',
                  type: 'demonstration',
                };
                setIsConsultLoading(true);
                axiosClient('/saveform', data, 'POST').then(() => setIsConsultLoading(false));

                setIsShowConsulting(true);
              }}
              className="transition-all flex items-center group cursor-pointer w-full"
            >
              <div className="flex items-center group-hover:text-green">
                <Calendar width={'16'} height={'16'} />
                <span className="font-medium lg:font-normal mt-0.5 lg:mt-0 text-base lg:text-sm text-black block ml-2 whitespace-nowrap group-hover:text-green self-center relative left-[3px]">
                  {'Заказать бесплатную консультацию'}
                </span>
              </div>
            </div>
          </button>
          <span className="text-[12px] leading-[14px] text-[#858585] block pl-4 mt-1 mb-2">
            Расскажем о платформе, как повысить
            <br />
            продажи, покажем примеры для вашего
            <br />
            бизнеса, ответим на любые вопросы
          </span>
        </div>
      )}
    </div>
  );
};

const Chats = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef();
  const { isMobile } = useUI();
  useClickOutside(ref, () => setIsExpanded(false));
  const links = ChatLinks.map((el) => {
    const { id, link, onClick, tooltip, Icon, iconClassName = '', iconSize = 30 } = el;
    return (
      <a
        key={id}
        target="_blank"
        rel="noreferrer"
        href={link}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick(() => setIsExpanded(false));
          }
        }}
        className="transition-all flex items-center pl-2 pr-3 py-3.5 lg:py-1 group hover:bg-green-200 cursor-pointer"
      >
        <Icon width={iconSize} height={iconSize} className={iconClassName} />
        <span className="font-medium lg:font-normal mt-0.5 lg:mt-0 text-base lg:text-sm text-gray-450 block ml-3 whitespace-nowrap group-hover:text-green self-center">
          {tooltip}
        </span>
      </a>
    );
  });
  const handleExpandClick = () => {
    setIsExpanded(true);
  };
  return (
    <div className="relative flex lg:-mt-0.5">
      <button
        className={cn('ml-3.5 text-green', {
          '!text-black': isExpanded && !isMobile,
          'self-center mr-3 ': isMobile,
        })}
        onClick={handleExpandClick}
      >
        <ChatsOpenerIcon width={20} height={20} />
      </button>
      {!isMobile ? (
        <HelpModal
          outerRef={ref}
          isHidden={!isExpanded}
          externalClasses="z-[1] block !opacity-100 transition-opacity before:opacity-100 py-2 -left-[172px] before:-z-10 !min-w-fit before:left-auto before:right-5"
        >
          {links}
        </HelpModal>
      ) : (
        <MobileInterfaceV2
          onPanelClose={() => setIsExpanded(false)}
          rootClassName="absolute bottom-0"
          isActive={isExpanded}
          headerText="Связаться с нами"
        >
          <div className="px-4">
            <div className="divide-y">{links}</div>
            <div className="py-4">
              <button
                className="rounded border border-gray-250 py-2 bg-gray-50 w-full"
                onClick={() => setIsExpanded(false)}
              >
                <span className="mx-auto block text-[#F01717] leading-[22px]">Закрыть</span>
              </button>
            </div>
          </div>
        </MobileInterfaceV2>
      )}
    </div>
  );
};

export default Chats;
