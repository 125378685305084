import React, { useMemo, useState, lazy } from 'react';
import cn from 'classnames';

import { useUI } from 'context/ui.context';
import { declOfNum } from 'utils/client';
import Modal from 'components/Modal';
import { useProduct } from 'context/product.context';
import PropTypes from 'prop-types';

const Carousel = lazy(() => import('components/Carousel'));
const PHOTO_SIZE = 250;
export const ProductPhotos = ({ isInner }) => {
  const [showFullGalleryModal, setShowFullGalleryModal] = useState(false);

  const { isDesktop } = useUI();

  const { productData } = useProduct();

  const images = useMemo(() => {
    const images = !isInner ? productData?.image : productData?.images;
    return (
      images?.map((item) => ({
        original: `${item}`,
        thumbnail: `${item}`,
        width: PHOTO_SIZE,
        height: PHOTO_SIZE,
      })) || []
    );
  }, [productData, isInner]);

  return (
    <>
      {isDesktop && (
        <div className="product-small-slider mb-3">
          <Carousel
            photoSize={PHOTO_SIZE}
            images={images}
            onClick={() => setShowFullGalleryModal(true)}
            showBullets={true}
            showLoop={true}
            renderItem={(item) => (
              <div
                className="bg-center bg-no-repeat bg-contain product-small-slider-item rounded-lg border border-gray-100"
                style={{ backgroundImage: `url(${item.original})` }}
              />
            )}
          />
        </div>
      )}

      {!isDesktop && (
        <div className="-mx-4 mb-1 pb-3 flex justify-start flex-1 flex-nowrap touch-auto overflow-x-auto overflow-y-hidden">
          {images.map((img, i) => (
            <div
              key={img.original}
              className={cn('shrink-0 overflow-hidden border rounded-[8px] ml-2', {
                ['ml-4']: i === 0,
              })}
            >
              <img
                onClick={() => setShowFullGalleryModal(true)}
                className="w-[140px] h-[140px] object-cover"
                src={img.original}
                alt="no-img"
              />
            </div>
          ))}
        </div>
      )}

      <Modal
        title={`${images?.length} ${declOfNum(images?.length, [
          'фотография',
          'фотографии',
          'фотографий',
        ])}`}
        isOpen={showFullGalleryModal}
        closeModal={() => setShowFullGalleryModal(false)}
        footer={false}
        width={600}
      >
        <div className="flex items-center justify-center">
          <div className="w-3/6 h-3/6 product-big-slider">
            <Carousel images={images} showThumbnails />
          </div>
        </div>
      </Modal>
    </>
  );
};

ProductPhotos.propTypes = {
  isInner: PropTypes.bool,
};
