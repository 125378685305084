import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { TABLE_CELL__CUSTOM_DATA_FIELDS } from 'constants/table';
import { useProductLink } from 'hooks/useLocationPath';
import { useChartDoughnut } from 'context/chart.doughnut.context';

const TableCell = ({ data, link, value }) => {
  const { activeIndex, setActiveIndex, removeActiveIndex, getColorByIndex } = useChartDoughnut();

  const index = data[TABLE_CELL__CUSTOM_DATA_FIELDS.INDEX];
  const bgColor = getColorByIndex(index);

  const eventHandlers = {
    onMouseEnter: () => setActiveIndex(index),
    onMouseLeave: () => removeActiveIndex,
  };

  if (!value) {
    return <></>;
  }

  return (
    <span {...eventHandlers} className="flex items-center gap-3">
      <div className="relative">
        <div className="w-2 h-2 rounded-full flex-none" style={{ background: bgColor }} />
        <div
          className="w-2 h-2 rounded-full flex-none absolute top-0"
          style={{ border: `1px solid rgba(0, 0, 0, 0.1)` }}
        />
      </div>
      <div
        className={classNames('flex flex-1 flex-wrap md:flex-nowrap items-start justify-between', {
          ['cursor-pointer']: link,
          ['text-green']: index === activeIndex,
          ['text-black']: index !== activeIndex,
        })}
      >
        <span>{value}</span>
      </div>
    </span>
  );
};
TableCell.propTypes = {
  data: PropTypes.object,
  link: PropTypes.string,
  value: PropTypes.string,
};

export const TableCellProductVariationLink = ({ data, value }) => {
  const productId = data[TABLE_CELL__CUSTOM_DATA_FIELDS.ID];
  const link = useProductLink(productId);

  return (
    <Link to={productId ? link : null}>
      <TableCell data={data} value={value} />
    </Link>
  );
};

TableCellProductVariationLink.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
};

export const TableCellProductVariation = ({ data, value }) => (
  <TableCell data={data} value={value} />
);

TableCellProductVariation.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
};
